import Head from 'next/head';
import styles from "../styles/pages/Home.module.scss"
import Link from 'next/link'
import Image from 'next/image';
import Slider from "react-slick";
import Instagallery from '../components/InstaGallery/Instagallery';
import { roomsData } from "../data/roomsData";
import { blogsData } from "../data/blogsData";
import BestDeal from '../components/BestDeal/BestDeal';
import FAQs from '../components/Faqs';
import HomePageVideo from '../components/Banner/HomePageVideo';
import AboutSection from '../components/Reusable/AboutSection';
import ContactLink from '../components/Reusable/ContactLink';
import BreadCrumbSchema from '../components/Schema/HotelSchema/breadcrumb';
import AccomodationSchema from '../components/Schema/HotelSchema/accomodation';
import HotelSchema from '../components/Schema/HotelSchema/hotel-schema';
import LocalBusinessSchema from '../components/Schema/HotelSchema/local-business';
import LodgingReservationSchema from '../components/Schema/HotelSchema/lodgingreservation';

export const getStaticProps = async () => {
  return {
    props: {
      Allrooms: roomsData,
      Allblogs: blogsData
    }
  }
}

export default function Home({ Allrooms, Allblogs }) {

  const roomsSettings = {
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const bannersettings = {
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false
  };

  const settings = {
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const activities = [
    {
      title: "Sky Swinger",
      bgImage: "/images/sky-swinger.jpg",
      description: "Sky Swinger is a new way to experience the sky. As an aerial playground, Sky Swinger offers thrilling opportunities for people to experience the rush of adrenaline."
    },
    {
      title: "Go Kart",
      bgImage: "/images/karting-attra.jpg",
      description: "Go-karts are the ultimate way to channel your inner child. We know you've got the skills and the moves, but it's time to get out of your comfort zone with Go Karting."
    },
    {
      title: "Zip Line",
      bgImage: "/images/zip-line.webp",
      description: "Zipline offers a unique, exhilarating experience you'll never forget. Our expert instructors help participants feel secure during the flight."
    },
    {
      title: "Bungee Ejection",
      bgImage: "/images/bungee.jpg",
      description: " Bungee Injection is a new adventure activity that combines one of the most adrenaline-pumping experiences in the world with a fun, friendly environment."
    },
    {
      title: "Indoor activities",
      bgImage: "/images/outdoor.jpg",
      description: "At Adventure Resorts, we have a wide variety of indoor activities to keep you entertained. We have dashing cars, haunted house, 5D theater and much more. You will never feel bored."
    },
  ]

  const testimonial = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    // autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const testimonialData = [
    {
      userImage: "/images/user.jpg",
      userName: "Shri kant",
      place: "From India",
      description: "It was a great pleasure to stay at The Twin Towers Hotel. I have been there many times & always feel like just home. The service,staff are very professional ."
    },
    {
      userImage: "/images/user.jpg",
      userName: "Priyankona",
      place: "From India",
      description: "Great Service! Awesome food! Spacious & comfortable room with breathtaking view. Absolutely Genuine, friendly and caring staff. Hotel is situated at a great location with beautiful scenic views. Would love to visit again!"
    },
    {
      userImage: "/images/user.jpg",
      userName: "Hitesh Jain",
      place: "From India",
      description: "What a wonderful place it is. You can not get more fun anywhere else in this area. Clean, good food, good views, lots of fun activities."
    },
  ]

  const bannerData = [
    {
      // bgImage: "/images/home-banner.webp",
      bgImage: "/images/banners/monsoon-banner-1.webp",
      bgImageMobile: '/images/banners/monsoon-banner-mobile-1.webp',
      titleone: "Embrace the warmth and luxury at its finest",
      hyperlink: "#",
      // target: "_blank"
      // titletwo: "luxury at its finest"
    },
    {
      // bgImage: "/images/adventure-banner.webp",
      bgImage: "/images/banners/monsoon-banner-2.webp",
      bgImageMobile: '/images/banners/monsoon-banner-mobile-2.webp',
      titleone: "A complete tourist destination",
      hyperlink: "https://adventureresortkufri.com/",
      target: "_blank"
      // titletwo: "luxury at its finest"
    }, {
      // bgImage: "/images/Gallery/res-banner3.jpg",
      bgImage: "/images/banners/winter-banner-3.webp",
      bgImageMobile: '/images/banners/winter-banner-3.webp',
      titleone: "Enjoy hearty meal in a calm and relaxing atmosphere.",
      hyperlink: "#",
      // target: "_blank"
      // titletwo: "and relaxing atmosphere."
    },
  ]

  const faqsData = [
    {
      id: 1,
      accordionQuestion: "What makes The Twin Towers Hotel the best luxury hotel in Kufri?",
      accordionAnswer: "The Twin Towers Hotel offers a tranquil and pleasant atmosphere in the heart of Kufri, amid the Himalayan foothills, making it the best luxury hotel in the area.        "
    },
    {
      id: 2,
      accordionQuestion: "Where is The Twin Towers Hotel located?",
      accordionAnswer: "The Twin Towers Hotel is located in the heart of Kufri town, amid the picturesque Himalayan foothills."
    },
    {
      id: 3,
      accordionQuestion: "What activities are available around The Twin Towers Hotel?",
      accordionAnswer: "The Twin Towers Hotel offers various fun activities, including adventure sports, nature walks, and scenic trails."
    },
    {
      id: 4,
      accordionQuestion: "How can I book my stay at The Twin Towers Hotel in Kufri?",
      accordionAnswer: "You can book your stay at The Twin Towers Hotel through online hotel booking platforms for a seamless and memorable experience in Shimla."
    },
    {
      id: 5,
      accordionQuestion: "Why should I choose The Twin Towers Hotel for my stay in Shimla?",
      accordionAnswer: "The Twin Towers Hotel provides a beautiful retreat with luxurious amenities, a serene atmosphere, and various fun activities, making it the perfect choice for your stay in Shimla.        "
    }
  ];

  return (
    <>
      <Head>
        <title>The Twin Towers Hotel in Kufri | Best Hotels in Shimla.</title>
        <meta name="description" content="Welcome to The Twin Tower, the 4 star Luxury best hotel in Kufri Shimla that offers Facilities, Deluxe Rooms, and affordable Budget Stay in Kufri." />
        <link rel="icon" href="/favicon.svg" />
        <link rel="canonical" href="https://www.hoteltwintowers.com" />
        <meta property="og:image" content="https://www.hoteltwintowers.com/images/hotel-twin-tower-featured.jpeg" />
        <meta name="keywords" content="hotel in kufri himachal online hotel booking in shimla Luxury Hotel In Kufri, Shimla Best Hotel in Kufri Hotel in kufri Hotel Kufri, 4 star hotels in shimla, 4 star hotel in kufri, best hotel in shimla affordable hotel in shimla, budget hotel in shimla, hotel in kufri shimla, top hotels in shimla, hotels in new Kufri"></meta>
        <link rel="preload" as="image" href='/images/banners/monsoon-banner-1.webp' type="image/webp" fetchpriority="high" />
        <link rel="preload" as="image" href='/images/banners/monsoon-banner-mobile-1.webp' type="image/webp" fetchpriority="high" media="(max-width: 767px)" />
      </Head>

      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <Slider {...bannersettings} className="banner_slider">
          {bannerData.map((item, index) => (
            <div className={styles.home_banner} key={index}>
              <Image
                src={item.bgImage}
                alt="Background Image"
                fill
                style={{ objectFit: 'cover' }}
                quality={100}
                priority={true}
                loading="eager"
                className={styles.home_banner_image}
                sizes="(max-width: 768px) 100vw, 1920px"
              />
              <Image
                src={item.bgImageMobile}
                alt="Background Image"
                fill
                style={{ objectFit: 'cover' }}
                quality={100}
                priority={index === 0}
                loading={index === 0 ? 'eager' : 'lazy'}
                className={styles.mobiel_home_banner_image}
                sizes="(max-width: 768px) 100vw, 100vw"
              />
              <div className={styles.banner_text}>
                <div className="container">
                  <div className={`${styles.banner_text_con} common_title`}>
                    <h2>{item.titleone}</h2>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <AboutSection />

      {/* <PricePopup /> */}
      <section className={`${styles.booking_sec} common_padding pb-0`}>
        <div className='container'>
          <BestDeal />
          <Slider {...roomsSettings} className="rooms_slider">
            {Allrooms.map((room) => (
              <div key={room.slug}>
                <div className={`${styles.room_con} row common_padding`}>
                  <div className={`${styles.room_left} col-lg-4 common_title`}>
                    <h3>{room.roomName}</h3>
                    <p>{room.roomDesc}</p>
                    <ContactLink phoneNumber="+91 93181-92605" iconSrc="/icons/call-black.svg" />

                    {/* <Link href="tel:9318192605" className={styles.room_contact_no}>
                      <div className='icon_con'>
                        <Image src="/icons/call-black.svg" height={21} width={21} alt="call-black-icon" />
                      </div>
                      <p className='m-0'> +91 93181-92605</p>
                    </Link> */}
                  </div>
                  <div className={`${styles.room_center} col-lg-4 first-order-mobile`}>
                    <Image
                      src={room.roomsImage}
                      width={410}
                      height={360}
                      alt={room.roomName + " Image"}
                      priority
                    />
                  </div>
                  <div className={`${styles.room_right} col-lg-4`}>
                    <div className={styles.room_right_bottom}>
                      <div className={`${styles.room_right_image} icon_con`}>
                        <Image src="/icons/key.svg" height={21} width={21} alt="key-icon"></Image>
                      </div>
                      <div className={styles.right_bottom_text}>
                        <p>Smart Key</p>
                        <span>Smart key card that enables you to access your room electronically without ever having to worry about lost keys again!</span>
                      </div>
                    </div>
                    <div className={styles.room_right_bottom}>
                      <div className='icon_con'>
                        <Image src="/icons/valley.svg" height={21} width={21} alt="valley-icon"></Image>
                      </div>
                      <div className={styles.right_bottom_text}>
                        <p>Valley View</p>
                        <span>Nestled among the Himalayas, our hotel offers views of breathtaking valleys and mountains.</span>
                      </div>
                    </div>
                    <div className={styles.room_right_bottom}>
                      <div className='icon_con'>
                        <Image src="/icons/wifi.svg" height={21} width={21} alt="wifi-icon"></Image>
                      </div>
                      <div className={styles.right_bottom_text}>
                        <p>Home Wi-Fi</p>
                        <span>We provide free wifi with a high-speed connection, so you can work on the go with all the amenities of home</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className={`${styles.button_sec} col-md-12 text-center`}>
            <Link href="/hotel-room-in-shimla" className="secound_common_btn">View All Rooms</Link>
          </div>
        </div>
      </section>

      <HomePageVideo />

      <section className={`${styles.hall_sec} common_padding pb-0`} id="events">
        <div className='container'>
          <div className='common_title text-center'>
            {/* <h4>Halls</h4> */}
            <h2>We does Fulfill your all needs</h2>
          </div>
          <div className={`${styles.image_sec} row`}>
            <div className="col col-12 col-sm-12 col-md-6 col-lg-6">
              <div className={styles.image_sec_left}>
                <Image
                  src='/images/wedding-hall.webp'
                  alt="destination wedding in shimla"
                  fill
                  style={{ objectFit: 'cover' }}
                  quality={100}
                  sizes="(max-width: 768px) 100vw, 1920px"
                />
                <Link className={`${styles.conference_btn} common_btn`} href="/contact-us">Wedding</Link>
                <div className={styles.image_overlay}>
                  <h3>WEDDING</h3>
                  <p>Our banquet halls are equipped with state-of-the-art technologies, including cutting edge lighting, audio-visual equipment and exquisite décor that can be customized to match your dream event. We also offer a variety of amenities with our packages, such as in-house catering, in-room dining and more.</p>
                  <Link className='secound_common_btn' href="/banquet-halls">ENQUIRE NOW</Link>
                </div>
              </div>
            </div>
            <div className="col col-12 col-sm-12 col-md-6 col-lg-6">
              <div className={styles.image_sec_left}>
                <Image
                  src='/images/cooperate-event.webp'
                  alt="conference hall in shimla"
                  fill
                  style={{ objectFit: 'cover' }}
                  quality={100}
                  sizes="(max-width: 768px) 100vw, 1920px"
                />
                <Link className={`${styles.conference_btn} common_btn`} href="/contact-us">Corporate Events</Link>
                <div className={styles.image_overlay}>
                  <h3>CORPORATE EVENTS</h3>
                  <p>We are home to one of the largest event spaces in Hiamchal, Our auditorium is a perfect space for hosting conferences, exhibitions and other large meetings. Let us help you bring your idea to life with our state of the art facilities & services with plenty of parking spaces for cars, buses and vans, etc. Our team is dedicated to making your event a success.</p>
                  <Link className='secound_common_btn' href="/conference-hall">ENQUIRE NOW</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.activities_sec} common_padding pb-0`} id="activity_scroll">
        <div className='container'>
          <div className='common_title text-center'>
            <h4>ACTIVITIES</h4>
            <h2>Explore our Adventure Activities</h2>
          </div>
          <div className={styles.activities_slider}>
            <Slider {...settings} className="activity_slider" ariaHidden={false}>
              {activities.map((activity, index) => (
                <div className={styles.activity_child} key={index}>
                  <div className={styles.activity_bg_image} >
                    <Image src={activity.bgImage} fill alt={activity.title} sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 1000px" />
                    <span>{activity.title}</span>
                    <div className={styles.activity_overlay}>
                      <p>{activity.description}</p>
                      <p>Click Here to: <Link href="https://www.adventureresortkufri.com/rides-and-activities" target="_blank">Buy Ticket</Link></p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className={styles.explore_btn}>
              <p>Get exclusive 10% OFF on all adventure activities at Adventure Resorts, <strong><Link href="https://www.adventureresortkufri.com" target="_blank">Buy Ticket</Link></strong></p>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.blogs_sec} common_padding pt-0`}>
        <div className='container '>
          <div className='common_title text-center'>
            <h4>Blogs</h4>
            <h2>Read our Latest Blogs</h2>
          </div>
          <div className={styles.blogs}>
            {Allblogs.slice(0, 3).map((blog) => (
              <Link href={`/blogs/${blog.slug}`} className={`${styles.single_blog_parent}`} key={blog.slug}>
                <div className={styles.all_blog_image}>
                  <Image
                    src={blog.bgImage}
                    alt={blog.title}
                    fill
                    style={{ objectFit: 'cover' }}
                    sizes="(max-width: 767px) 100vw, 412px"
                  >
                  </Image>
                </div>
                <div className={`${styles.all_blog_content} d-flex`}>
                  <div>
                    <p className={styles.blog_date}>{blog.date}</p>
                    <h4 className={styles.blog_title}>{blog.blog_title}</h4>
                    <p>{blog.shortDescription}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className={`${styles.button_sec} col-md-12 text-center`}><Link className="secound_common_btn" href="/blogs">View All Blogs</Link></div>
        </div>
      </section>

      <section className={`${styles.help_sec}`}>
        {/* <Image src="/images/help-bg.jpg" layout='fill' objectFit='cover' alt="icon"></Image> */}
        <div className='container'>
          <div className={`${styles.help_sec_child} row align-items-center`}>
            <div className='col-md-8 common_title'>
              <h2>Need any help? Please contact with Us!</h2>
            </div>
            <div className='col-md-4'>
              <Link href="tel:9318192605" className="icon_text">
                <div className='icon_con black_icon_con'>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1A1A1A"><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" /></svg>
                </div>
                <p className=''> +91 93181-92605</p>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.testimonial_sec} common_padding`}>
        <div className={styles.background_image}>
          <Image
            src="/images/testimonial-bg.jpg"
            alt="Testimonial Background"
            fill
          />
        </div>
        <div className='container'>
          <div className='common_title text-center ' style={{ position: 'relative' }}>
            <h4 className='text-white'>testimonials</h4>
            <h2>What Our Clients Say</h2>
          </div>

          <Slider {...testimonial} className="testi_slider">
            {testimonialData.map((item, index) => (
              <div className={`${styles.testimonial}`} key={index}>
                <div className='row justify-content-center'>
                  <div className={`${styles.testi_right} col-md-6 col-sm-12 common_title`}>
                    <p>{item.description}</p>
                    <h4>{item.place}</h4>
                    <h3>{item.userName}</h3>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <div className='common_padding pb-0'>
        <div className="container">
          <h2 className="hotel_page_hotel_haeding mb-2 text-center">FAQs</h2>
          <FAQs faqsData={faqsData} />
        </div>
      </div>

      <Instagallery />
      <BreadCrumbSchema />
      <AccomodationSchema />
      <HotelSchema />
      <LocalBusinessSchema />
      <LodgingReservationSchema />
    </>
  )
}

// import SingleHotelsearchform from '../components/Search/SingleHotelsearchform';
// import PricePopup from '../components/pricePopup';
// import ParamsForm from '../components/Search/ParamsForm';

//  <section className={styles.banner_video}>
//         <div id='player'></div>
//   </section>
//  <section className={styles.banner_video}>
//         <iframe src='https://www.youtube.com/embed/keTV9UFHNpA?controls=0&autoplay=1&mute=1&rel=0&showinfo=0'
//           frameBorder='0'
//           allow='autoplay; encrypted-media'
//           allowFullScreen
//           width="100%"
//           height="100%"
//           title='video'
//           className={styles.video_bg}
//         />
// </section>
// https://www.youtube.com/embed/keTV9UFHNpA?controls=0&autoplay=1&mute=1&rel=0&showinfo=0&modestbranding=0&loop=1
{/* <SingleHotelsearchform /> */ }
{/* <ParamsForm /> */ }
{/* <img className={styles.first_img} src="/images/about-sec-1.jpg" alt="Hotel Twin Tower Banner One" />
              <img className={styles.centered_img} src="/images/about-sec-2.jpg" alt="Hotel Twin Tower Banner Two" />
              <img className={styles.last_img} src="/images/about-sec-3.jpg" alt="Hotel Twin Tower Banner Three" /> */}