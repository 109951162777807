import React, { useState, useEffect } from 'react';
import axios from "axios";
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const DynamicSlider = dynamic(() => import('react-slick'), {
  ssr: false,
});

export default function Instagallery() {
  const [isLoading, setIsLoading] = useState(true);
  const [feeds, setFeeds] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchFeeds = () => {
      axios
        .get('https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,caption&limit=10&access_token=IGQWRQM0lNY3RZAMDZA2ZA3p1NTBKamJ6OUlNci1td3BiVFlhek1oRjI0TkNjSGFxcnBuU1ZABdUFLMlVMU3VSTU9ibFhtMTB3dTBDU1ctMFExdDRaWGY5UXAxeThITVYtVFFsLUdXWVk2cGxJUE9Cbzk2ak5kajZAWcHcZD')
        .then((res) => {
          setFeeds(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchFeeds();
  }, []);

  const filtered_feed = feeds.filter(item => {
    return item.media_type != "VIDEO";
  });

  function getWords(str) {
    return str.split(/\s+/).slice(0, 5).join(" ");
  }

  return (
    <section className="insta_gallery_section common_padding pb-0">
      <div className='common_title text-center'>
        <h4>instagram feed</h4>
        <h2 className='insta_username'><Link href="https://www.instagram.com/hotel_twintowers/" target="_blank">@hotel_twintowers</Link></h2>
      </div>
      {isLoading ? (
        <div className="text-center">
          <p>Loading ...</p>
        </div>
      ) : (
        <DynamicSlider {...settings} className="insta_gallery_slider">
          {filtered_feed.map((feed) => (
            <div className="instaimg" key={feed.id}>
              <Image
                id={feed.id}
                src={feed.media_url}
                fill={true}
                alt={getWords(feed.caption)}
                quality={75}
                sizes="(max-width: 768px) 100vw, 1920px"
                loading="lazy"
              />
              <Link href={feed.permalink} target="_blank" className="middle">
                <Image src="/icons/instagram-icon.svg" height={30} width={30} alt='instagram-icon'></Image>
              </Link>
            </div>
          ))}
        </DynamicSlider>
      )}
    </section>
  )
}